import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { BladeViewIsLoading } from '@aventus/blade';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { queryParamRemove } from '@aventus/pocketknife/query-param-remove';
import { useAsyncEffect } from 'use-async-effect';
import { useAuth0Client } from '@aventus/auth0-client-context';

export const AuthCallback: React.FunctionComponent<IAuthCallback> = (props) => {

  const auth0 = useAuth0Client();
  const [ isHandled, setIsHandled ] = React.useState<boolean>(false);

  useAsyncEffect(async () => {
    if (!isHandled && auth0) {
      await auth0.handleRedirectCallback();
      setIsHandled(true);
    }
  });

  if (!isHandled) {
    return (
      <BladeViewIsLoading
        variant={ 's' } />
    );
  }

  const pathname = queryParamGet(props.location.search, 'pathname');

  const updatedSearch = queryParamRemove(
    props.location.search,
    [ 'pathname', 'code', 'state' ]
  );

  return (
    <Redirect
      to={ `${ pathname }?${ updatedSearch }` } />
  );

};

interface IAuthCallback extends RouteComponentProps {}
