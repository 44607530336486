import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest, ProductReference, ProductCoverReference } from '@aventus/platform';

export const useStartIntelligentQuoteForNew: UseStartIntelligentQuoteForNew = function (
  productReference,
  productCoverReference,
  quoteId,
  partnerId,
  isContinuing
) {

  const symphony = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteRequest, setIntelligentQuoteRequest ] = React.useState<IntelligentQuoteRequest | undefined>(undefined);
  const [ isStartingIntelligentQuoteForNew, setIsStartingIntelligentQuoteForNew ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (
        ((productReference && productCoverReference) || quoteId) &&
        !isContinuing
      ) {

        setIsStartingIntelligentQuoteForNew(true);

        try {

          const _intelligentQuoteRequest = await symphony.startIntelligentQuoteForNew(
            productReference,
            productCoverReference,
            quoteId,
            partnerId
          );

          setIntelligentQuoteRequest(_intelligentQuoteRequest);

        } catch (error) {
          throwError(error);
        }

        setIsStartingIntelligentQuoteForNew(false);

      }

    }());
  }, [ productReference, productCoverReference, quoteId ]);

  return {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForNew
  };

}

export type UseStartIntelligentQuoteForNew = (
  productReference?: ProductReference,
  productCoverReference?: ProductCoverReference,
  quoteId?: string,
  partnerId?: string,
  isContinuing?: boolean
) => {
  intelligentQuoteRequest: IntelligentQuoteRequest | undefined,
  isStartingIntelligentQuoteForNew: boolean
}
