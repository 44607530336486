import * as React from 'react';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import {
  useGetOraclesConfiguration,
  useGetProductConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import { useGetOrganisationConfiguration } from '@aventus/configuration-client-context';
import { ProductReference, ProductCoverReference } from '@aventus/platform';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useGetOrganisationSettings } from '@aventus/opus-client/hooks';
import { useAskForAdjust } from './use-ask-for-adjust';
import { useGetPolicy } from '@aventus/opus-client/hooks';
import { useGetPolicyActions } from '@aventus/opus-client/hooks';

export const AskForAdjust: React.FunctionComponent<IAskForAdjust> = props => {
  const policyId: string = queryParamGet(props.location.search, 'policyId');
  const partnerId: string = queryParamGet(props.location.search, 'partnerId');

  const { policy } = useGetPolicy(policyId);
  const { policyActions } = useGetPolicyActions(policyId);

  React.useEffect(() => {
    if (policy) {
      setProductReference(policy.product?.referenceID.toLowerCase());
      setProductCoverReference(
        policy.latestPolicyHistory?.coverType.toLowerCase()
      );
    }
  }, [policy]);

  const _productReference: ProductReference = queryParamGet(
    props.location.search,
    'productReference'
  );

  const _productCoverReference: ProductCoverReference = queryParamGet(
    props.location.search,
    'productCoverReference'
  );

  const [productReference, setProductReference] = React.useState<
    ProductReference | undefined
  >(_productReference);
  const [productCoverReference, setProductCoverReference] = React.useState<
    ProductCoverReference | undefined
  >(_productCoverReference);

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );

  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    productReference,
    productCoverReference
  );

  const { oraclesConfiguration } = useGetOraclesConfiguration();

  const { orgSettings, isGettingOrgSettings } = useGetOrganisationSettings();

  const iq = useAskForAdjust(policyId, partnerId);

  if (
    organisationConfiguration &&
    productConfiguration &&
    oraclesConfiguration &&
    productInterfaceConfiguration &&
    !isGettingOrgSettings &&
    orgSettings && 
    policyActions
  ) {

    const canAdjustPolicy = policyActions.actions.find(
      action => action.referenceID === 'avt_wfs_policymta'
    );

    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        goHome={props.goHome}
        currencyCode={orgSettings.currencyCode}
        currencySymbol={orgSettings.currencySymbol}
        countryCode={orgSettings.defaultCountryCode}
        dateFormat={orgSettings.dateFormat}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        organisationConfiguration={organisationConfiguration}
        oraclesConfiguration={oraclesConfiguration}
        getHomeRoute={props.getHomeRoute}
        getSummaryRoute={props.getSummaryRoute}
        skipConfirmationPage={false}
        questionsConfig={{
          hideVoucher: true
        }}
        mtaAction={canAdjustPolicy}
        quoteType='MTA'
      />
    );
  }
  return null; // This is standard behavior if we can't load
};

interface IAskForAdjust extends IIntelligentQuote {}
