import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest } from '@aventus/platform';

export const useStartIntelligentQuoteForSavedQuote: UseStartIntelligentQuoteForSavedQuote = function (
  quoteID
) {

  const symphony = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteRequest, setIntelligentQuoteRequest ] = React.useState<IntelligentQuoteRequest | undefined>(undefined);
  const [ isStartingIntelligentQuoteForSavedQuote, setIsStartingIntelligentQuoteForSavedQuote ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (quoteID) {

        setIsStartingIntelligentQuoteForSavedQuote(true);

        try {

          const _intelligentQuoteRequest = await symphony.startIntelligentQuoteForSavedQuote(quoteID);
          setIntelligentQuoteRequest(_intelligentQuoteRequest);

        } catch (error) {
          throwError(error);
        }

        setIsStartingIntelligentQuoteForSavedQuote(false);

      }

    }());
  }, [ quoteID ]);

  return {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForSavedQuote
  };

}

export type UseStartIntelligentQuoteForSavedQuote = (
  quoteID?: string,
) => {
  intelligentQuoteRequest: IntelligentQuoteRequest | undefined,
  isStartingIntelligentQuoteForSavedQuote: boolean
}
