import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { Risk, QuoteBundle } from '@aventus/platform';


export const useNextIntelligentQuoteForAdditionalQuestions: UseNextIntelligentQuote = function (
  risk,
  riskState,
  riskHash
) {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteBundle, setQuoteBundle ] = React.useState<QuoteBundle | undefined>(undefined);
  const [ isGettingQuoteBundle, setIsGettingQuoteBundle ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {    
      if (risk && riskState) {

        setIsGettingQuoteBundle(true);

        try {

          const checkIfWeHaveQuestionsAnswered = await opus.nextIntelligentQuote(risk, riskState);

          if(checkIfWeHaveQuestionsAnswered.metadata.questionSetComplete === true){
            const _intelligentQuoteResponse = await opus.nextQuoteAdditionalQuestion(
              risk,
              riskState,
              true
            )
            setQuoteBundle(_intelligentQuoteResponse);
          }

        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteBundle(false);

      }

    }());
  }, [ riskHash ]);

  return {
    intelligentQuoteBundle,
    isGettingQuoteBundle
  };

}

export type UseNextIntelligentQuote = (
  risk: Risk,
  riskState: string,
  riskHash: string
) => {
  intelligentQuoteBundle: QuoteBundle | undefined;
  isGettingQuoteBundle: boolean
}
