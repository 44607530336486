import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigurationClientContext } from '@aventus/configuration-client-context';
import { OpusClientContext } from '@aventus/opus-client/hooks';
import { Auth0ClientContext } from '@aventus/auth0-client-context';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useApp } from './use-app';
import { TrackApp } from '@aventus/application-tracking';

import '@aventus/css-reset';

import { AuthHandler } from './auth/auth-handler';
import { AuthAutoLogin } from './auth/auth-auto-login';
import { AuthCallback } from './auth/auth-callback';
import { AppAuthenticated } from './app-authenticated';
import { BladeAppLoader, BladeRunner } from '@aventus/blade';
import { OpusBoundary } from './boundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const OpusApp: React.FunctionComponent<IOpusApp> = () => {
  const tenantReference = queryParamGet(
    window.location.search,
    'tenantReference'
  );
  const tenantOrgReference = queryParamGet(
    window.location.search,
    'tenantOrgReference'
  );

  const orgLevelToken = queryParamGet(window.location.search, 'orgToken');
  const token = queryParamGet(window.location.search, 'token');
  const userId = queryParamGet(window.location.search, 'userId');

  const originPathname = window.location.pathname;
  const originSearch = window.location.search;

  const {
    isReady,
    isExternalAuth,
    configuration,
    opus,
    auth0,
    interfaceConfiguration
  } = useApp(
    tenantReference,
    tenantOrgReference,
    { pathname: originPathname, search: originSearch },
    token,
    orgLevelToken
  );
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <TrackApp app={'opus'} appVersion={__PKG_VERSION__}>
          <BladeAppLoader off={isReady} />
          {isReady && (
            <BladeRunner {...interfaceConfiguration}>
              <OpusBoundary>
                <ConfigurationClientContext.Provider value={configuration}>
                  <Auth0ClientContext.Provider value={auth0}>
                    <OpusClientContext.Provider value={opus}>
                      <Router>
                        <Switch>
                          <Route
                            exact
                            path={'/login/callback'}
                            render={props => <AuthCallback {...props} />}
                          />

                          <Route
                            path={'/'}
                            render={() => (
                              <AuthHandler
                                isExternalAuth={isExternalAuth}
                                authenticatedApp={
                                  <AppAuthenticated
                                    orgLevelToken={orgLevelToken}
                                    userId={userId}
                                  />
                                }
                                unauthenticatedApp={<AuthAutoLogin />}
                              />
                            )}
                          />
                        </Switch>
                      </Router>
                    </OpusClientContext.Provider>
                  </Auth0ClientContext.Provider>
                </ConfigurationClientContext.Provider>
              </OpusBoundary>
            </BladeRunner>
          )}
        </TrackApp>
      </QueryClientProvider>
    </>
  );
};

interface IOpusApp {}
