import * as React from 'react';

// We temporarily need to import the Auth0 types directly
// until they are exported with the main package index itself.
// see: https://github.com/auth0/auth0-spa-js/issues/39

import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

export const Auth0ClientContext = React.createContext<Auth0Client | undefined>(undefined);

export function useAuth0Client (): Auth0Client {
  const client: Auth0Client | undefined = React.useContext(Auth0ClientContext);
  if (client === undefined) {
    throw new Error('useAuth0Client must be used within a Auth0ClientContext.Provider');
  }
  return client;
}
