import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { PolicyActionsWrapper } from '@aventus/platform';

export const useGetPolicyActions = (policyId: string) => {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ policyActions, setPolicyActions ] = React.useState<PolicyActionsWrapper | undefined>(undefined);
  const [ isGettingPolicy, setIsGettingPolicyActions ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (policyId) {

        setIsGettingPolicyActions(true);

        try {

          const _getPolicyActions = await opus.getPolicyActions(policyId);
          setPolicyActions(_getPolicyActions);

        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyActions(false);

      }

    }());
  }, [ policyId ]);

  return {
    policyActions,
    isGettingPolicy
  };

}

export interface RequestResponse {
  type: string,
  policyId: string
}

export interface SuccessResponse {
  type: string,
  policyActions: PolicyActionsWrapper
}
