import * as React from 'react';
import { useAuth0Client } from '@aventus/auth0-client-context';

export const AuthAutoLogin: React.FunctionComponent<IAuthAutoLogin> = () => {

  const auth0 = useAuth0Client();

  React.useEffect(() => {
    auth0.loginWithRedirect();
  }, []);

  return null;
};

interface IAuthAutoLogin {}
