import * as React from 'react';
import { useGetOrganisationSettings } from '@aventus/opus-client/hooks';
import { ApplicationTimezoneContext } from '@aventus/application-timezone';
import { ApplicationInterfaceProvider } from '@aventus/application-interface';
import css from './index.css';

export const Frame: React.FunctionComponent<IFrame> = props => {
  const { orgSettings, isGettingOrgSettings } = useGetOrganisationSettings();

  if (isGettingOrgSettings) {
    return null;
  }

  if (orgSettings) {
    // The timezone provider should ideally live in
    // the root OpusApp component, which sets up all the providers
    // for the React app. However, since Opus is designed differently
    // to our other apps - primarily in the way we handle authorisation -
    // we need to move this provider a layer lower, but at the root of
    // the authenticated app, since the data for this provider
    // can only come after the user is authorised.

    return (
      <ApplicationInterfaceProvider>
        <ApplicationTimezoneContext.Provider
          value={{
            orgTimezone: orgSettings?.timezone,
            formats: {
              human: 'Do MMMM YYYY',
              platform: 'YYYY-MM-DDTHH:mm:ss'
            }
          }}
        >
          <div className={css.frame}>{props.children}</div>
        </ApplicationTimezoneContext.Provider>
      </ApplicationInterfaceProvider>
    );
  }

  return null;
};

interface IFrame {}
