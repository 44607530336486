import * as React from 'react';
import {
  useIntelligentQuoting,
  UseIntelligentQuoting
} from '@aventus/mvmt-quote';
import { IntelligentQuoteRequest } from '@aventus/platform';
import {
  useStartIntelligentQuoteForSavedQuote,
  useNextIntelligentQuote,
  useNextQuoteIntelligentQuote,
  useSaveIntelligentQuote
} from '@aventus/opus-client/hooks';

export function useAskForSaved(
  quoteId?: string,
  partnerId?: string,
  isAutoNext?: boolean,
  existingIntelligentQuoteRequest?: IntelligentQuoteRequest
): UseAskForNew {

  const intelligentQuoting = useIntelligentQuoting(
    isAutoNext,
    quoteId,
    partnerId
  );

  // Start an intelligent quote, and listen out for the response.
  // On response, fire the callback in the useEffect below to handle
  // the response within the API agnostic mvmt services.

  const {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForSavedQuote
  } = useStartIntelligentQuoteForSavedQuote(quoteId);

  React.useEffect(() => {
    if (intelligentQuoteRequest) {
      intelligentQuoting.handleStart(intelligentQuoteRequest, "saved");
    }

    if (existingIntelligentQuoteRequest) {
      intelligentQuoting.handleStart(existingIntelligentQuoteRequest, "saved");
    }
  }, [intelligentQuoteRequest]);

  // Next an intelligent quote, and listen out for the response.
  // On response, fire the callback in the useEffect below to handle
  // the response within the API agnostic mvmt services.

  const {
    intelligentQuoteResponse,
    isGettingIntelligentQuoteResponse
  } = useNextIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash,
    intelligentQuoting.customizationNext
  );

  React.useEffect(() => {
    if (intelligentQuoteResponse) {
      intelligentQuoting.handleNext(intelligentQuoteResponse);
    }
  }, [intelligentQuoteResponse]);

  // Next quote an intelligent quote, and listen out for the response.
  // On response, fire the callback in the useEffect below to handle
  // the response within the API agnostic mvmt services.

  const {
    intelligentQuoteBundle,
    isGettingQuoteBundle
  } = useNextQuoteIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.confirm,
    'new',
    intelligentQuoting.quoteState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash
  );

  React.useEffect(() => {
    if (intelligentQuoteBundle) {
      intelligentQuoting.handleNextQuote(intelligentQuoteBundle);
    }
  }, [intelligentQuoteBundle]);

  // Save a quote. As long as the active quote is still in partial mode,
  // and no price has been generated for it, the user can opt to save
  // the quote in that state to return to later.

  const {
    intelligentQuoteBundle: savedIntelligentQuoteBundle,
    isSavingQuote
  } = useSaveIntelligentQuote(
    intelligentQuoting.shouldSaveQuote,
    false,
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    'NewBusiness',
  );

  React.useEffect(() => {
    if (savedIntelligentQuoteBundle) {
      intelligentQuoting.handleSavedQuote(savedIntelligentQuoteBundle);
    }
  }, [savedIntelligentQuoteBundle])

  return {
    ...intelligentQuoting,
    isWorking:
      isStartingIntelligentQuoteForSavedQuote ||
      isGettingIntelligentQuoteResponse ||
      isGettingQuoteBundle ||
      isSavingQuote
  };
}

export interface UseAskForNew extends UseIntelligentQuoting {
  isWorking: boolean;
}
