import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Frame } from './frame';
import { useStartSession } from '@aventus/opus-client/hooks';
import { BladeViewIsLoading } from '@aventus/blade';
import { IntelligentQuote } from './views/intelligent-quote';
import { Checkout } from './views/checkout';
import { PolicySummaryOfCover } from './views/policy-summary-of-cover';
import { PolicyRenew } from './views/policy-renew';

export const AppAuthenticated: React.FunctionComponent<IAppAuthenticated> = props => {
  const { hasSessionStarted, isStartingSession } = useStartSession(
    props.userId,
    props.orgLevelToken
  );

  if (isStartingSession) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (!isStartingSession && hasSessionStarted) {
    return (
      <Frame>
        <Switch>
          <Route
            exact
            path={'/quote/:quoteActionOrId'}
            render={props => (
              <IntelligentQuote
                {...props}
                goHome={() => '/'}
                getHomeRoute={() => '/'}
                getSummaryRoute={(quoteId: string) => `/quote/${quoteId}`}
              />
            )}
          />

          <Route
            exact
            path={'/checkout/:quoteId'}
            render={props => <Checkout {...props} />}
          />

          <Route
            exact
            path={'/policy/summary/:policyId'}
            render={props => <PolicySummaryOfCover {...props} />}
          />

          <Route
            exact
            path={'/policy/:policyId/renew'}
            render={props => (
              <PolicyRenew
                {...props}
                goHome={() => '/'}
                goToOrigin={() => '/'}
              />
            )}
          />
        </Switch>
      </Frame>
    );
  }

  return null;
};

interface IAppAuthenticated {
  orgLevelToken: string;
  userId: string;
}
