import * as React from 'react';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { PolicyBundle } from '@aventus/platform';
import { BladeView, BladeTitleBar } from '@aventus/blade';
import { YourDetails } from '../../components/your-details';
import { YourPolicy } from '../../components/your-policy';
import { useApplicationTimezone } from '@aventus/application-timezone/use-application-timezone';
import css from './index.css';
import { DetailsOfDrivers } from '@aventus/mvmt-quote/components/details-of-drivers';
import { PolicyPremiumBreakdown } from '@aventus/mvmt-policy/components/policy-premium-breakdown';

export const PolicySummaryOfCoverBasic: React.FunctionComponent<IPolicySummaryOfCoverBasic> = props => {
  const { toHumanReadable } = useApplicationTimezone();

  return (
    <div className={css.scaffold}>
      {props.productConfiguration?.policy.summaryOfCover.title && (
        <BladeTitleBar
          viewVariant={'s'}
          title={props.productConfiguration?.policy.summaryOfCover.title}
        />
      )}

      <BladeView variant={'s'} className={css.policyOverview}>
        {props.productConfiguration.quote.summary.showInsurerDetails && (
          <YourDetails
            proposer={props.policyBundle.risk.proposer}
            toHumanReadable={toHumanReadable}
            showEmail={false}
            shouldRespectViewWidth={true}
          />
        )}

        <YourPolicy
          policyBundle={props.policyBundle}
          policyLabel={props.productConfiguration.product.label}
          isMotorPolicy={
            props.productConfiguration.quote.summary.showMotorDetails
          }
          toHumanReadable={toHumanReadable}
          shouldRespectViewWidth={true}
          paymentPlanLabels={
            props.productConfiguration.quote.summary.summaryPaymentPlanLabels
          }
          coverStartLabel={props.productConfiguration.quote.summary.coverStartLabel}
          coverEndLabel={props.productConfiguration.quote.summary.coverEndLabel}
          showPurchasedDate={props.productConfiguration.policy.summary.showPurchasedDate}
        />

        {props.productConfiguration.quote.summary.showDriverDetails && (
          <DetailsOfDrivers
            proposer={props.policyBundle.risk.proposer}
            driverDetails={props.policyBundle.risk.vehicle.driverDetails}
            shouldRespectViewWidth={true}
          />
        )}
        {props.policyBundle.totalPaid &&
          props.productConfiguration.quote.summary.showPremiumSummary && (
            <PolicyPremiumBreakdown
              policyBundle={props.policyBundle}
              productConfiguration={props.productConfiguration}
              shouldRespectViewWidth={true}
            />
          )}
      </BladeView>
    </div>
  );
};

export interface IPolicySummaryOfCoverBasic {
  productConfiguration: InsuranceProductConfiguration;
  policyBundle: PolicyBundle;
}
