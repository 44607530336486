import * as React from 'react';
import { useIsAuthenticated } from '@aventus/auth0-client-context';

export const AuthHandler: React.FunctionComponent<IAuthHandler> = (props) => {

  // If auth has happened externally, then we'll just assume
  // the app is authenticated, and we'll let opus API try and start
  // session, and bail if not authorised.

  if (props.isExternalAuth) {
    return props.authenticatedApp;
  }

  // If the client is not authorised yet, then Opus
  // will take care of redirecting to Auth0's
  // hosted login and auth handling.

  const { isAuthenticated, hasCheckedIsAuthenticated } = useIsAuthenticated();

  if (!isAuthenticated && hasCheckedIsAuthenticated) {
    return props.unauthenticatedApp;
  }

  if (isAuthenticated && hasCheckedIsAuthenticated) {
    return props.authenticatedApp;
  }

  return null;

};

interface IAuthHandler {
  isExternalAuth: boolean,
  authenticatedApp: React.ReactElement | null,
  unauthenticatedApp: React.ReactElement | null
}
