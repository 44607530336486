import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteResponse, QuoteRateableState, Risk } from '@aventus/platform';

export const useNextIntelligentQuote: UseNextIntelligentQuote = function (
  risk,
  tobesState,
  quoteRateableState,
  riskHash,
  customizationNext
) {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteResponse, setIntelligentQuoteResponse  ] = React.useState<IntelligentQuoteResponse | undefined>(undefined);
  const [ isGettingIntelligentQuoteResponse, setIsGettingIntelligentQuoteResponse ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if ((risk && tobesState && riskHash) && (quoteRateableState === 'NotRateable'  || (quoteRateableState === 'Rateable' && customizationNext === true ))) {

        setIsGettingIntelligentQuoteResponse(true);

        try {

          const _intelligentQuoteResponse = await opus.nextIntelligentQuote(
            risk,
            tobesState
          );

          setIntelligentQuoteResponse(_intelligentQuoteResponse);

        } catch (error) {
          throwError(error);
        }

        setIsGettingIntelligentQuoteResponse(false);

      }

    }());
  }, [ riskHash, customizationNext ]);

  return {
    intelligentQuoteResponse,
    isGettingIntelligentQuoteResponse
  };

}

export type UseNextIntelligentQuote = (
  risk: Risk,
  tobesState: string,
  quoteRateableState: QuoteRateableState,
  riskHash: string,
  customizationNext: boolean
) => {
  intelligentQuoteResponse: IntelligentQuoteResponse | undefined,
  isGettingIntelligentQuoteResponse: boolean
}
