import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { Quote } from '@aventus/platform';


export const useGetQuote: UseGetQuote = function (
  quoteId
) {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ quote, setQuote ] = React.useState<Quote | undefined>(undefined);
  const [ isGettingQuote, setIsGettingQuote ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (quoteId) {

        setIsGettingQuote(true);

        try {

          const _quote = await opus.getQuote(quoteId);
          setQuote(_quote);

        } catch (error) {
          throwError(error);
        }

        setIsGettingQuote(false);

      }

    }());
  }, [ quoteId ]);

  return {
    quote,
    isGettingQuote
  };

}

export type UseGetQuote = (
  quoteId?: string
) => {
  quote: Quote | undefined,
  isGettingQuote: boolean
}
