import * as React from 'react';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration,
  useGetOraclesConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import {
  useGetOrganisationSettings,
  useGetQuote
} from '@aventus/opus-client/hooks';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useAskForNew } from './use-ask-for-new';
import { useSessionStorage } from '@aventus/pockethooks';
import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const AskForNew: React.FunctionComponent<IAskForNew> = props => {
  // A brand new quote can be started by defining the product reference
  // and the product cover reference of the intended insurance product.
  // In this scenario, the query for this view is:
  // ...?productReference=X&productCoverReference=Y

  const _productReference: ProductReference = queryParamGet(
    props.location.search,
    'productReference'
  );
  const _productCoverReference: ProductCoverReference = queryParamGet(
    props.location.search,
    'productCoverReference'
  );

  const [productReference, setProductReference] = React.useState<
    ProductReference | undefined
  >(_productReference);
  const [productCoverReference, setProductCoverReference] = React.useState<
    ProductCoverReference | undefined
  >(_productCoverReference);

  // Given these two references, we can pick out the specific configuration
  // for this insurance product, as well as the organisation configuration.

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    productReference,
    productCoverReference
  );
  const { oraclesConfiguration } = useGetOraclesConfiguration();

  // Disabling the scroll to prevent from showing inside iframe after dynamic
  // adjustment of height from the portal sid

  if (productConfiguration?.quote.ask.disableBodyScroll) {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  // Alternatively, a quote can be started off the back of an existing
  // quote. The benefit of doing this is to have all of the answers a user
  // previously gave pre-filled in this new quote.
  // In this scenario, the query for this view is:
  // ...?quoteId=X
  // Meaning, the _productReference and _productCoverReference above would be null.

  const quoteId: string = queryParamGet(props.location.search, 'quoteId');

  // If we're loading an existing quote, because all we've got is the
  // quote id, then we need fetch the quote object and pick out the   product
  // and product cover reference to be able to load the correct configuration
  // file for that product.

  const { quote } = useGetQuote(quoteId);

  React.useEffect(() => {
    if (quote) {
      setProductReference(quote.productReferenceID.toLowerCase());
      setProductCoverReference(quote.coverType.toLowerCase());
    }
  }, [quote]);

  // If the user was re-directed by one of our partners, then we'd expect
  // that partner ID to be in our query params or localstorage under key partnerId
  // If it is, then we need to it on to IQ Start so that we can attribute the conversion to them.

  const { storageValue } = useSessionStorage('partnerId');

  const partnerId: string =
    queryParamGet(props.location.search, 'partnerId') || storageValue;

  const showExpiry: boolean =
    queryParamGet(props.location.search, 'showExpiry') === 'true';

  // As mentioned above, we can initialise a new quote using
  // either references or an existing quote id.
  // We can use either to create our IQ instance and render
  // that to the UI.

  const iq = useAskForNew(
    productReference,
    productCoverReference,
    quoteId,
    partnerId
  );

  // Lastly we need to fetch the currency settings for this particular
  // organisation.

  const { orgSettings } = useGetOrganisationSettings();

  // Once we've got the product configuration,
  // we can assume we've done all the preparing we need to,
  // so we can go ahead and load the quote form.
  // Note that we don't need to wait for any of the IQ async
  // actions or initialisation. This is because the component
  // knows how to handle the IQ object in any of it's possible
  // states.

  if (
    productConfiguration &&
    organisationConfiguration &&
    orgSettings &&
    productInterfaceConfiguration &&
    oraclesConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        quote={quote}
        defaultPaymentPlanType={props.defaultPaymentPlanType}
        goHome={props.goHome}
        currencyCode={orgSettings.currencyCode}
        currencySymbol={orgSettings.currencySymbol}
        countryCode={orgSettings.defaultCountryCode}
        dateFormat={orgSettings.dateFormat}
        organisationConfiguration={organisationConfiguration}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        oraclesConfiguration={oraclesConfiguration}
        getSummaryRoute={props.getSummaryRoute}
        getHomeRoute={props.getHomeRoute}
        skipConfirmationPage={
          productConfiguration.quote.ask.skipConfirmationScreenForOpus
            ? productConfiguration.quote.ask.skipConfirmationScreenForOpus
            : false
        }
        quoteType="NewBusiness"
        showExpiry={showExpiry}
      />
    );
  }

  return null;
};

interface IAskForNew extends IIntelligentQuote {}
