import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import createAuth0Client from '@auth0/auth0-spa-js';
import { createConfigurationClient, ConfigurationClient } from '@aventus/configuration-client';
import { createOpusClient, OpusClient } from '@aventus/opus-client';
import { compile, combine } from '@aventus/honey-compiler';
import { honeyCompilerCSSBrowser } from '@aventus/honey-compiler-css-browser';
import { honeyAtomicOperatorWebfonts } from '@aventus/honey-atomic-operator-webfonts';
import { honeyMolecularSpacing } from '@aventus/honey-molecular-spacing';
import { IDENTITY_OPUS } from './design-system';
import { InterfaceConfiguration } from '@aventus/configuration';

export function useApp (
  tenantReference: string | undefined,
  tenantOrgReference: string | undefined,
  origin: { pathname: string, search: string },

  /*
  Auth0 authorisation can either happen within opus, or pre-authorised
  outside of Opus and the resulting token passed in. This is by design since Opus
  is largely designed to be an integrated back-office solution.
  In the case that auth has been handled outside, all we need to do is
  initialise the Opus API client's session start with this token.
  */

  token?: string,

/*
This org level token is passed into Opus if we wish to skip or not use
the built in auth0 authentication. We need this token to validate a
user scope in absense of auth0 validation
*/

  orgLevelToken?: string
) {

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  // Flag to indicate the auth0 authorisation happened
  // externally.

  const [ isExternalAuth ] = React.useState<boolean>((token && typeof token === 'string') || orgLevelToken ? true : false);

  const [ configuration, setConfiguration ] = React.useState<ConfigurationClient | undefined>(undefined);
  const [ interfaceConfiguration, setInterfaceConfiguration ] = React.useState<InterfaceConfiguration | undefined>(undefined);
  const [ auth0, setAuth0 ] = React.useState<any>(undefined);
  const [ opus, setOpus ] = React.useState<OpusClient | undefined>(undefined);

  useAsyncEffect(async () => {
    if (!isReady) {

      // First we'll create the configuration client,
      // so that we can fetch the code for this applications.

      const configuration = createConfigurationClient('opus');
      const opusConfiguration = await configuration.getAppConfiguration();

      if (tenantReference && tenantOrgReference) {
        configuration.overrideTenantReferences(tenantReference, tenantOrgReference);
      }

      const applicationConfiguration = await configuration.getApplicationsConfiguration('opus');
      const designSystemConfiguration = await configuration.getDesignSystemConfiguration(applicationConfiguration.designSystem);
      const organisationConfiguration = await configuration.getOrganisationConfiguration(tenantReference, tenantOrgReference);
      const interfaceConfiguration = await configuration.getInterfaceConfiguration();
      // If this app was started at a specific path, we want to
      // pass these on the callback, so that if the agent needs to go through
      // authentication, the callback is given the information needed
      // to route them to the correct path.


      var auth0RedirectUrl = applicationConfiguration.auth0.redirectURI ? applicationConfiguration.auth0.redirectURI : opusConfiguration.AUTH0_REDIRECT_URI;

      const redirectURI = auth0RedirectUrl + `?pathname=${ origin.pathname }&${ origin.search.replace('?', '') }`;

      // Once we've got our app configuration and all relevant
      // environment vars, we can initalise auth0.

      const _auth0 = (
        isExternalAuth
          ? undefined
          : (
              await createAuth0Client({
                domain: applicationConfiguration.auth0.domain ? applicationConfiguration.auth0.domain : opusConfiguration.AUTH0_DOMAIN,
                client_id: applicationConfiguration.auth0.clientID ? applicationConfiguration.auth0.clientID : opusConfiguration.AUTH0_CLIENT_ID,
                redirect_uri: redirectURI,
                audience: applicationConfiguration.auth0.audience ? applicationConfiguration.auth0.audience : opusConfiguration.AUTH0_AUDIENCE,
                connection: applicationConfiguration.auth0.connection ? applicationConfiguration.auth0.connection : opusConfiguration.AUTH0_CONNECTION
              })
            )
      );

      // With auth0 now initialised, we can create our Opus
      // client, which has a (optional) dependency on the auth0
      // client we just created.

      const opus = createOpusClient(
        { baseURL: organisationConfiguration.opusApiUrl },
        opusConfiguration.API_VERSION,
        _auth0,
        token
      );

      compile(
        combine([
          designSystemConfiguration,
          IDENTITY_OPUS
        ]),
        [ honeyCompilerCSSBrowser ],
        [ honeyAtomicOperatorWebfonts ],
        honeyMolecularSpacing
      );

      setConfiguration(configuration);
      setInterfaceConfiguration(interfaceConfiguration);
      setOpus(opus);
      setAuth0(_auth0);
      setIsReady(true);

    }
  }, []);

  return {
    isReady,
    isExternalAuth,
    configuration,
    auth0,
    opus,
    interfaceConfiguration
  };

}
