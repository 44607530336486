import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest } from '@aventus/platform';

export const useStartIntellegentQuoteForAdditionalQuestions : UseStartIntelligentQuoteForAdditionalQuestions = function (
    quoteId,
    questionSetName, 
    isContinuing
    ) {

        const opus = useOpusClient();
        const { throwError } = useError();
        const [ intelligentQuoteRequest, setIntelligentQuoteRequest ] = React.useState<IntelligentQuoteRequest | undefined>(undefined);
        const [ isStartingIntelligentQuoteForAdditionalQuestions, setIsStartingIntelligentQuoteForAdditionalQuestions] = React.useState<boolean>(false);

        React.useEffect(() => {
            (async function () {
        
              if (
                questionSetName && 
                quoteId &&
                !isContinuing){
        
                setIsStartingIntelligentQuoteForAdditionalQuestions(true);
        
                try {
        
                  const _intelligentQuoteRequest = await opus.startAdditionalQuestionsForQuote(
                    quoteId,
                    questionSetName
                  );
        
                  setIntelligentQuoteRequest(_intelligentQuoteRequest);
        
                } catch (error) {
                  throwError(error);
                }
        
                setIsStartingIntelligentQuoteForAdditionalQuestions(false);
        
              }
        
            }());
          }, [ questionSetName, quoteId, isContinuing ]);

        return {
            intelligentQuoteRequest,
            isStartingIntelligentQuoteForAdditionalQuestions
          };
}


export type UseStartIntelligentQuoteForAdditionalQuestions = (
    quoteId: string,
    questionSetName: string,
    isContinuing?: boolean
  ) => {
    intelligentQuoteRequest: IntelligentQuoteRequest | undefined,
    isStartingIntelligentQuoteForAdditionalQuestions: boolean
  }
  