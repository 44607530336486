import * as React from 'react';
import {
  useIntelligentQuoting,
  UseIntelligentQuoting
} from '@aventus/mvmt-quote';
import {
  useStartIntellegentQuoteForAdditionalQuestions,
  useNextIntelligentQuoteForAdditionalQuestions,
  useNextIntelligentQuote
} from '@aventus/opus-client/hooks';
import { useTrack } from '@aventus/application-tracking';

export function useAskForAdditionalQuestions(
    quoteId: string,
    questionSetName: string,
    partnerId?: string,
    isContinuedQuote?: boolean
): useAskForAdditionalQuestions {

  const intelligentQuoting = useIntelligentQuoting(
    true,
    quoteId,
    partnerId
  );

  const { track } = useTrack();

    const {
      intelligentQuoteRequest,
      isStartingIntelligentQuoteForAdditionalQuestions
    } = useStartIntellegentQuoteForAdditionalQuestions(quoteId, questionSetName, isContinuedQuote);

    React.useEffect(()=> {
      if(intelligentQuoteRequest){
        intelligentQuoting.handleStart(intelligentQuoteRequest, "additional")

        track({
          event: 'aventus.quote.additionalQuestionsStarted',
          quoteId: intelligentQuoting.quoteId,
          productReference: intelligentQuoting.quote?.productReferenceID,
          productCoverReference: intelligentQuoting.quote?.coverType
        });
      }
    }, [intelligentQuoteRequest]);

    const {
      intelligentQuoteResponse,
      isGettingIntelligentQuoteResponse
    } = useNextIntelligentQuote(
      intelligentQuoting.risk,
      intelligentQuoting.riskState,
      intelligentQuoting.quoteRateableState,
      intelligentQuoting.riskHash,
      intelligentQuoting.customizationNext
    );


    React.useEffect(() => {
      if (intelligentQuoteResponse && !intelligentQuoteResponse.metadata.questionSetComplete) {
        intelligentQuoting.handleNext(intelligentQuoteResponse);
      }
    }, [intelligentQuoteResponse]);

    const {
      intelligentQuoteBundle,
      isGettingQuoteBundle
    } = useNextIntelligentQuoteForAdditionalQuestions(
      intelligentQuoting.risk,
      intelligentQuoting.riskState,
      intelligentQuoting.riskHash
    );

    React.useEffect(() => {
      if (intelligentQuoteBundle) {
        intelligentQuoting.handleNextQuote(intelligentQuoteBundle);
        track({
          event: 'aventus.quote.additionalQuestionsAnswered',
          quoteId: intelligentQuoteBundle.requestedQuote.id,
          productReference: intelligentQuoteBundle.requestedQuote.productReferenceID,
          productCoverReference: intelligentQuoteBundle.requestedQuote.coverType
        });

        track({
          event: 'aventus.renewal.confirm',
          quoteId: intelligentQuoteBundle.requestedQuote.id
        });
      }
    }, [intelligentQuoteBundle]);

    return {
      ...intelligentQuoting,
      isWorking:
        isStartingIntelligentQuoteForAdditionalQuestions ||
        isGettingIntelligentQuoteResponse ||
        isGettingQuoteBundle
    };

}


export interface useAskForAdditionalQuestions extends UseIntelligentQuoting {

}
