import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { AskForNew } from './ask-for-new';
import { AskForSaved } from './ask-for-saved';
import { Summary } from './summary';
import { PaymentPlanType, QuoteActions } from '@aventus/platform';
import { getDefaultPaymentPlanTypeQuery } from '@aventus/mvmt-pricing';
import { AskAdditionalQuestions } from './additional-questions';
import { useTrack } from '@aventus/application-tracking';
import useMutationObserver from '@rooks/use-mutation-observer';
import { AskForAdjust } from './ask-for-adjust';
import { AskForRenew } from './ask-for-renew';

export const IntelligentQuote: React.FunctionComponent<
  IIntelligentQuote
> = props => {
  if (!props.match.params.quoteActionOrId) {
    console.warn(
      'Could not render component, a quote action or ID was not defined in the path'
    );
    return null;
  } else {
    return <IntelligentQuoteSafe {...props} />;
  }
};

export const IntelligentQuoteSafe: React.FunctionComponent<
  IIntelligentQuote
> = props => {
  const { track } = useTrack();

  const [currenHeight, setCurrentHeight] = React.useState<number>(0);

  const mutationNode = React.useRef<HTMLHeadingElement>(null);

  const mutationCallBack = () => {
    if (mutationNode?.current?.clientHeight) {
      const heightChange = Math.abs(
        currenHeight - mutationNode?.current?.clientHeight
      );
      if (heightChange > 30 || currenHeight === 0) {
        track({
          event: 'aventus.app.scrollHeightChanged',
          height: mutationNode?.current?.clientHeight
        });
      }

      setCurrentHeight(mutationNode?.current?.clientHeight);
    }
  };

  useMutationObserver(mutationNode, mutationCallBack);
  const defaultPaymentPlanType = getDefaultPaymentPlanTypeQuery(
    props.location.search
  );

  const commonProps = {
    defaultPaymentPlanType,
    getHomeRoute,
    getSummaryRoute,
    goHome: () => props.history.push(getHomeRoute())
  };

  switch (props.match.params.quoteActionOrId) {
    case 'new':
    case 'edit':
      return (
        <div ref={mutationNode}>
          <AskForNew {...props} {...commonProps} />
        </div>
      );

    case 'saved':
      return (
        <div ref={mutationNode}>
          <AskForSaved {...props} {...commonProps} />
        </div>
      );

    case 'additional':
      return (
        <div ref={mutationNode}>
          <AskAdditionalQuestions {...props} {...commonProps} />
        </div>
      );

    case 'renew':
      return (
        <div ref={mutationNode}>
          <AskForRenew {...props} {...commonProps} />
        </div>
      );

    case 'adjust':
      return (
        <div ref={mutationNode}>
          <AskForAdjust {...props} {...commonProps} />
        </div>
      );
    default:
      return (
        <div ref={mutationNode}>
          <Summary
            {...props}
            quoteId={props.match.params.quoteActionOrId}
            defaultPaymentPlanType={defaultPaymentPlanType}
            goHome={() => props.history.push(getHomeRoute())}
            customiseQuote={(route: string) => {
              props.history.push(route, {
                origin: props.location.pathname
              });
            }}
            customiseQuoteForRenew={(quoteId: string) => {
              props.history.push(getEditQuoteForRenewRoute(quoteId), {
                origin: props.location.pathname
              });
            }}
            goToDocuments={(type: string, id: string) => {
              props.history.push(getDocumentsRoute(type, id), {
                origin: props.location.pathname
              });
            }}
            goToCheckout={(
              quoteId: string,
              defaultPaymentPlanType?: PaymentPlanType
            ) => {
              props.history.push(
                getCheckoutRoute(quoteId, defaultPaymentPlanType),
                {
                  origin: props.location.pathname
                }
              );
            }}
          />
        </div>
      );
  }
};

// Note that this whole component is meant to be pulled out
// to the app level. These fns should be defined by the app,
// through some sort of route configuration. They are temporarily
// places here.

const getSummaryRoute = (
  quoteId: string,
  defaultPaymentPlanType?: PaymentPlanType
) =>
  `/quote/${quoteId}${
    defaultPaymentPlanType ? `?plan=${defaultPaymentPlanType}` : ''
  }`;
const getHomeRoute = () => `/dashboard`;
const getCheckoutRoute = (
  quoteId: string,
  defaultPaymentPlanType?: PaymentPlanType
) =>
  `/checkout/${quoteId}${
    defaultPaymentPlanType ? `?plan=${defaultPaymentPlanType}` : ''
  }`;

const getEditQuoteForRenewRoute = (quoteId: string) =>
  `/quote/renew?quoteId=${quoteId}`;

const getDocumentsRoute = (type: string, id: string) =>
  `/${type}/${id}/documents`;

interface IPathParams {
  quoteActionOrId: QuoteActions;
}

export interface IIntelligentQuote extends RouteComponentProps<IPathParams> {
  defaultPaymentPlanType?: PaymentPlanType;
  getSummaryRoute: (quoteId: string) => string;
  getHomeRoute: () => string;
  goHome: () => void;
}
