import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteProduct } from '@aventus/platform';

export const useGetQuoteProduct: UseGetQuoteProduct = function (
  quoteId
) {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ quoteProduct, setQuoteProduct ] = React.useState<QuoteProduct | undefined>(undefined);
  const [ isGettingQuoteProduct, setIsGettingQuoteProduct ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (quoteId) {

        setIsGettingQuoteProduct(true);

        try {

          const _quoteProduct = await opus.getQuoteProduct(quoteId);
          setQuoteProduct(_quoteProduct);

        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteProduct(false);

      }

    }());
  }, [ quoteId ]);

  return {
    quoteProduct,
    isGettingQuoteProduct
  };

}

export type UseGetQuoteProduct = (
  quoteId?: string
) => {
  quoteProduct: QuoteProduct | undefined,
  isGettingQuoteProduct: boolean
}
