import URLSearchParams from '@ungap/url-search-params';

export function queryParamRemove (search: string, queryParams: string[]): string {

  const params = new URLSearchParams(search);

  for (const p of queryParams) {
    params.delete(p);
  }

  return params.toString();
}
