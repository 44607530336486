import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';

export const useStartSession: UseStartSession = (subjectUserId: string | undefined, orgToken: string | undefined) => {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ hasSessionStarted, setHasSessionStarted ] = React.useState<boolean>(false);
  const [ isStartingSession, setIsStartingSession ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function() {

        setIsStartingSession(true);
        try {
          await opus.startSession(subjectUserId, orgToken);
          setHasSessionStarted(true);
        } catch (error) {
          throwError(error);
        }

        setIsStartingSession(false);

    }());
  }, [ subjectUserId, orgToken ]);

  return {
    hasSessionStarted,
    isStartingSession
  };

}

export type UseStartSession = (
  subjectUserId: string,
  orgToken: string
) => {
  hasSessionStarted: boolean,
  isStartingSession: boolean
}
