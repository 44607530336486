import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { Policy } from '@aventus/platform';

export const useGetPolicy = (policyId: string) => {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ policy, setPolicy ] = React.useState<Policy | undefined>(undefined);
  const [ isGettingPolicy, setIsGettingPolicy ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (policyId) {

        setIsGettingPolicy(true);

        try {

          const _getPolicy = await opus.getPolicy(policyId);
          setPolicy(_getPolicy);

        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicy(false);

      }

    }());
  }, [ policyId ]);

  return {
    policy,
    isGettingPolicy
  };

}

export interface RequestResponse {
  type: string,
  policyId: string
}

export interface SuccessResponse {
  type: string,
  policy: Policy
}
