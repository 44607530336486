import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useOpusClient } from '@aventus/opus-client/hooks';
import {
  useGetProductConfiguration,
  useGetPaymentProvidersConfiguration,
  useGetOrganisationConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import { MvmtCheckout } from '@aventus/mvmt-checkout';
import { getDefaultPaymentPlanTypeQuery } from '@aventus/mvmt-pricing';
import { useGetQuoteBundle } from '@aventus/opus-client/hooks/use-get-quote-bundle';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { IntelligentQuoteExpiredOrConverted } from '@aventus/mvmt-quote/views/intelligent-quote-expired-or-converted';
import css from './index.css';

export const Checkout: React.FunctionComponent<ICheckout> = props => {
  const { quoteBundle } = useGetQuoteBundle(props.match.params.quoteId);

  const { productConfiguration } = useGetProductConfiguration(
    quoteBundle?.requestedQuote?.productReferenceID,
    quoteBundle?.requestedQuote?.coverType
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    quoteBundle?.requestedQuote?.productReferenceID,
    quoteBundle?.requestedQuote?.coverType
  );
  const { paymentProvidersConfiguration } =
    useGetPaymentProvidersConfiguration();
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const opus = useOpusClient();

  const defaultPaymentPlanType = getDefaultPaymentPlanTypeQuery(
    props.location.search
  );

  // If this quote is a renewal, then we can expect there
  // to be an original policy. This ID would be passed into
  // this view as a query param, so that we can link back into
  // the policy renewal dashboard if necessary.

  const originalPolicyId = queryParamGet(
    props.location.search,
    'originalPolicyId'
  );

  const { applicationConfiguration } = useGetApplicationConfiguration();

  const goToQuoteSummary = (quoteID: string, quotePlan: string) =>
    props.history.push(`/quote/${quoteID}?plan=${quotePlan}`);

  if (
    quoteBundle?.requestedQuote.converted === true &&
    productConfiguration &&
    organisationConfiguration
  ) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={productConfiguration.quote.converted.title}
          reason={productConfiguration.quote.converted.reason}
          help={productConfiguration.quote.converted.help}
          supportLink={organisationConfiguration.links.support}
        />
      </div>
    );
  }

  if (
    quoteBundle?.requestedQuote.expired === true &&
    productConfiguration &&
    organisationConfiguration
  ) {
    let expiryReason = productConfiguration.quote.expired.reason;
    if (quoteBundle?.requestedQuote.quoteType === 'NewBusiness' && productConfiguration.quote.expired.reasonNewBusiness){
      expiryReason = productConfiguration.quote.expired.reasonNewBusiness
    }
    else if (quoteBundle?.requestedQuote.quoteType === 'Renewal' && productConfiguration.quote.expired.reasonRenewal){
      expiryReason = productConfiguration.quote.expired.reasonRenewal
    }

    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={productConfiguration.quote.expired.title}
          reason={expiryReason}
          help={productConfiguration.quote.expired.help}
          supportLink={organisationConfiguration.links.support}
          onAcknowledgement={() => props.history.push('/dashboard')}
        />
      </div>
    );
  }

  if (
    quoteBundle &&
    productConfiguration &&
    productInterfaceConfiguration &&
    paymentProvidersConfiguration &&
    applicationConfiguration &&
    organisationConfiguration
  ) {
    return (
      <MvmtCheckout
        quoteId={quoteBundle.requestedQuote.id}
        quote={quoteBundle.requestedQuote}
        quoteEmbargoes={quoteBundle.embargoes}
        quotePricing={quoteBundle.requestedQuotePricing}
        defaultPaymentPlanType={defaultPaymentPlanType}
        risk={quoteBundle.risk}
        goToQuoteSummary={goToQuoteSummary}
        adjustmentInformation={quoteBundle.mtaInfo}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        paymentProvidersConfiguration={paymentProvidersConfiguration}
        organisationConfiguration={organisationConfiguration}
        applicationConfiguration={applicationConfiguration}
        emailAddress={quoteBundle.risk.proposer.emailAddress}
        callingApplication={'opus'}
        lifecycles={{
          onInitialise: opus.initialisePayment,
          onInitialiseSubscription: opus.initialiseSubscription,
          onPoll: opus.getPayment,
          onGetPurchasedPolicy: opus.getPolicyForPayment,
          onGetPolicy: opus.getPolicy,
          onCreateFinanceAgreement: opus.createFinanceAgreement,
          onUpdateFinanceAgreement: opus.updateFinanceAgreement,

          onCreateBNPFinanceAgreement: opus.createBNPFinanceAgreement,
          onCreateBNPFinanceAgreementNoDeposit:
            opus.createBNPFinanceAgreementNoDeposit,

          onStripeCreateSetupIntent: opus.stripeCreateSetupIntent,

          onFatZebraGetConfig: opus.fatZebraGetConfig,
          onFatZebraGeneratePaymentIntentVerification:
            opus.fatZebraGeneratePaymentIntentVerification,
          onFatZebraAnnualPurchase: opus.fatZebraAnnualPurchase,
          onFatZebraSubscriptionPurchase: opus.fatZebraSubscriptionPurchase,
          onFatZebraMidTermAdjustmentRefund:
            opus.fatZebraMidTermAdjustmentRefund,
          onFatZebraSubscriptionMTA: opus.fatZebraSubscriptionMTA,

          onAdjustRefund: opus.adjustRefund,
          onAdjustSubscription: opus.adjustSubscription,
          onApplyVoucherCode: opus.applyVoucherCode,
          onRemoveVoucherCode: opus.removeVoucherCode,
          onGetPolicyPaymentMethod: opus.getPolicyPaymentMethod,
          onFinish: policy => {
            props.history.push(getSummaryPolicyRoute(policy.id), {
              origin: props.location.pathname
            });
          },
          onClearPaymentToken: () => {}
        }}
        onBackToRenewal={() =>
          props.history.push(`/policy/${originalPolicyId}/renew`)
        }
        isAuthenticated={() => true}
      />
    );
  }

  return null;
};

const getSummaryPolicyRoute = (policyId: string) =>
  `/policy/summary/${policyId}`;

interface IPathParams {
  quoteId: string;
}

interface ICheckout extends RouteComponentProps<IPathParams> {}
