import * as React from 'react';
import { BladeIcon, BladeButton } from '@aventus/blade';
import { PlatformUnauthorisedError } from '@aventus/errors';
import { IOpusBoundaryProps, IOpusBoundaryState } from './types';
import css from './index.css';

export class OpusBoundary extends React.Component<
  IOpusBoundaryProps,
  IOpusBoundaryState
> {
  constructor(props: IOpusBoundaryProps) {
    super(props);

    this.state = {
      error: null,
      message: null
    };
  }

  // This need to be any as the typed Error object is not fully defined and is almost impossible to work with
  componentDidCatch(error: any) {
    if (
      error.response?.status === 401 ||
      error instanceof PlatformUnauthorisedError
    ) {
      this.setState({
        error,
        message:
          'It looks like you are unauthorised to perform this action. Try and sign in again or speak to someone with the right permissions.'
      });
    } else {
      this.setState({
        error,
        message:
          'An error occured trying to perform your action. Please try again or get in contact with us.'
      });
    }
  }

  render() {
    if (this.state.message && this.state.error) {
      return (
        <div className={css.boundary}>
          <BladeIcon
            className={css.boundary_icon}
            name={'farExclamationCircle'}
          />
          <h1 className={css.boundary_title}>Something went wrong</h1>
          <p className={css.boundary_message}>{this.state.message}</p>
          {this.state.error.response?.status !== 401 && (
            <BladeButton
              className={css.boundary_action}
              onClick={() => window.location.reload()}
              isInline={true}
            >
              Try again
            </BladeButton>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
