import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle } from '@aventus/platform';

export const useGetQuoteRenewal: UseGetQuoteRenewal = function (
  renewalQuoteId
) {
  const opus = useOpusClient();
  const { throwError } = useError();

  const [quoteBundle, setQuoteBundle] = React.useState<QuoteBundle | undefined>(
    undefined
  );
  const [isGettingQuoteRenewal, setIsGettingQuoteRenewal] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function () {
      if (renewalQuoteId) {
        setIsGettingQuoteRenewal(true);

        try {
          const _quoteBundle = await opus.getQuoteRenewal(renewalQuoteId);
          setQuoteBundle(_quoteBundle);
        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteRenewal(false);
      }
    })();
  }, [renewalQuoteId]);

  return {
    quoteBundle,
    isGettingQuoteRenewal
  };
};

export type UseGetQuoteRenewal = (
  renewalQuoteId?: string
) => {
  quoteBundle: QuoteBundle | undefined;
  isGettingQuoteRenewal: boolean;
};
