import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { IntelligentQuoteRequest } from '@aventus/platform';

export const useGetEditQuote = function (renewalQuoteId: string) {
  const symphony = useOpusClient();
  const { throwError } = useError();

  const [iqRequest, setIqRequest] = React.useState<
    IntelligentQuoteRequest | undefined
  >(undefined);
  const [isGettingIqRequest, setIsGettingPolicyAddons] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function () {
      if (renewalQuoteId) {
        setIsGettingPolicyAddons(true);

        try {
          const _getIQRequest = await symphony.editQuote(renewalQuoteId);
          setIqRequest(_getIQRequest);
        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyAddons(false);
      }
    })();
  }, [renewalQuoteId]);

  return {
    iqRequest,
    isGettingIqRequest
  };
};
