import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle, QuoteType } from '@aventus/platform';

export const useSaveIntelligentQuote: UseSaveIntelligentQuote = function (
  shouldSaveQuote,
  confirmQuote,
  risk,
  tobesState,
  type,
  partnerId,
  partnerReference,
  sourceVriID
) {

  const symphony = useOpusClient();
  const { throwError } = useError();

  const [ intelligentQuoteBundle, setIntelligentQuoteBundle ] = React.useState<QuoteBundle | undefined>(undefined);
  const [ isSavingQuote, setIsSavingQuote ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (shouldSaveQuote === true && tobesState && type && risk) {

        setIsSavingQuote(true);

        try {

          const _intelligentQuoteBundle = await symphony.saveIntelligentQuote(
            confirmQuote,
            risk,
            tobesState,
            type,
            partnerId,
            partnerReference,
            sourceVriID
          );

          setIntelligentQuoteBundle(_intelligentQuoteBundle);

        } catch (error) {
          throwError(error);
        }

        setIsSavingQuote(false);

      }

    }());
  }, [ shouldSaveQuote ]);

  return {
    intelligentQuoteBundle,
    isSavingQuote
  };

}

export type UseSaveIntelligentQuote = (
  shouldSaveQuote: boolean,
  confirmQuote: boolean,
  risk: any,
  tobesState: string,
  type: QuoteType,
  partnerId?: string,
  partnerReference?: string,
  sourceVriID?: string
) => {
  intelligentQuoteBundle: QuoteBundle | undefined,
  isSavingQuote: boolean
}
