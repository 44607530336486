import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { OrganisationSettings } from '@aventus/opus-client';

export const useGetOrganisationSettings: UseGetOrganisationSettings = () => {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ orgSettings, setOrgSettings ] = React.useState<OrganisationSettings | undefined>(undefined);
  const [ isGettingOrgSettings, setIsGettingOrgSettings ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      setIsGettingOrgSettings(true);

      try {
        const _orgSettings = await opus.getOrganisationSettings();
        setOrgSettings(_orgSettings);
      } catch (error) {
        throwError(error);
      }

      setIsGettingOrgSettings(false);

    }());
  }, []);

  return {
    orgSettings,
    isGettingOrgSettings
  };

}

export type UseGetOrganisationSettings = () => {
  orgSettings: OrganisationSettings | undefined,
  isGettingOrgSettings: boolean
}
