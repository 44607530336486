import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import {
  useGetPolicyBundle
} from '@aventus/opus-client/hooks';
import { MvmtPolicySummaryOfCoverBasic } from '@aventus/mvmt-policy';

export const PolicySummaryOfCover: React.FunctionComponent<IPolicySummaryOfCover> = props => {
  const { policyBundle} = useGetPolicyBundle(props.match.params.policyId)
  const { productConfiguration } = useGetProductConfiguration(policyBundle?.product?.referenceID, policyBundle?.risk?.coverType);
  
  if (
    policyBundle &&
    productConfiguration
  ) {
    return (
      <MvmtPolicySummaryOfCoverBasic
        productConfiguration={ productConfiguration }
        policyBundle = {policyBundle}
        goHome={ () => props.history.push('/') }
        goToOrigin={ () => props.history.push('/') } />

    );
  }

  return null;

};

interface IPathParams {
  policyId: string;
}

interface IPolicySummaryOfCover extends RouteComponentProps<IPathParams> {}
