import { IDesignSystem } from '@aventus/honey-definitions';

export function combine (designSystems: IDesignSystem[]): IDesignSystem {
  return (
    designSystems.reduce(
      (designSystem: IDesignSystem, currentDesignSysytem: IDesignSystem) => ({
        atoms: [
          ...(designSystem.atoms || []),
          ...(currentDesignSysytem.atoms || [])
        ],
        molecules: [
          ...(designSystem.molecules || []),
          ...(currentDesignSysytem.molecules || [])
        ]
      }),
      {}
    )
  );
}
