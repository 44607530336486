import * as React from 'react';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration,
  useGetOraclesConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useAskForAdditionalQuestions } from './use-ask-additional-questions';
import { useSessionStorage } from '@aventus/pockethooks';
import {
  useGetOrganisationSettings,
  useGetQuote
} from '@aventus/opus-client/hooks';

export const AskAdditionalQuestions: React.FunctionComponent<IAskAdditionalQuestions> = props => {
  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { orgSettings } = useGetOrganisationSettings();

  const quoteId: string = queryParamGet(props.location.search, 'quoteId');
  const questionSet: string = queryParamGet(
    props.location.search,
    'questionSet'
  );

  const { storageValue } = useSessionStorage('partnerId');
  const partnerId: string =
    queryParamGet(props.location.search, 'partnerId') || storageValue;

  const { quote } = useGetQuote(quoteId);

  const iq = useAskForAdditionalQuestions(quoteId, questionSet, partnerId);

  const { productConfiguration } = useGetProductConfiguration(
    quote?.productReferenceID.toLowerCase(),
    quote?.coverType.toLowerCase()
  );

  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    quote?.productReferenceID.toLowerCase(),
    quote?.coverType.toLowerCase()
  );

  const { oraclesConfiguration } = useGetOraclesConfiguration();

  // Disabling the scroll to prevent from showing inside iframe after dynamic
  // adjustment of height from the portal sid

  if (productConfiguration?.quote.ask.disableBodyScroll) {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  if (
    orgSettings &&
    productConfiguration &&
    organisationConfiguration &&
    productInterfaceConfiguration &&
    oraclesConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        quote={quote}
        defaultPaymentPlanType={props.defaultPaymentPlanType}
        currencyCode={orgSettings.currencyCode}
        currencySymbol={orgSettings.currencySymbol}
        countryCode={orgSettings.defaultCountryCode}
        dateFormat={orgSettings.dateFormat}
        organisationConfiguration={organisationConfiguration}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        oraclesConfiguration={oraclesConfiguration}
        goHome={props.goHome}
        getSummaryRoute={props.getSummaryRoute}
        getHomeRoute={props.getHomeRoute}
        skipConfirmationPage={false}
        quoteType={quote?.quoteType}
      />
    );
  }

  return null;
};

interface IAskAdditionalQuestions extends IIntelligentQuote {}
