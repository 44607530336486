import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { PolicyBundle } from '@aventus/platform';

export const useGetPolicyBundle = (policyId: string) => {

  const opus = useOpusClient();
  const { throwError } = useError();

  const [ policyBundle, setPolicyBundle ] = React.useState<PolicyBundle | undefined>(undefined);
  const [ isGettingPolicyBundle, setIsGettingPolicyBundle ] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async function () {

      if (policyId) {

        setIsGettingPolicyBundle(true);

        try {
          const _getBundle = await opus.getPolicyBundle(policyId);
          setPolicyBundle(_getBundle);

        } catch (error) {
          throwError(error);
        }

        setIsGettingPolicyBundle(false);

      }

    }());
  }, [ policyId ]);

  return {
    policyBundle,
    isGettingPolicyBundle
  };

}
