import * as React from 'react';
import { useOpusClient } from './context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle } from '@aventus/platform';

export const useGetQuoteBundle: UseGetQuoteEmbargoes = function (quoteId) {
  const opus = useOpusClient();
  const { throwError } = useError();

  const [quoteBundle, setQuoteBundle] = React.useState<
  QuoteBundle | undefined
  >(undefined);
  const [isGettingQuoteBundle, setIsGettingQuoteBundle] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    (async function () {
      if (quoteId) {
        setIsGettingQuoteBundle(true);

        try {
          const _quoteBundle = await opus.getQuoteBundle(quoteId);
          setQuoteBundle(_quoteBundle);
        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteBundle(false);
      }
    })();
  }, [quoteId]);

  return {
    quoteBundle,
    isGettingQuoteBundle
  };
};

export type UseGetQuoteEmbargoes = (
  quoteId?: string
) => {
  quoteBundle: QuoteBundle | undefined;
  isGettingQuoteBundle: boolean;
};
