import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useAuth0Client } from './context';

export function useIsAuthenticated (): UseIsAuthenticated {

  const auth0 = useAuth0Client();
  const [ isAuthenticated, setIsAuthenticated ] = React.useState<boolean>(false);
  const [ hasCheckedIsAuthenticated, setHasCheckedIsAuthenticated ] = React.useState<boolean>(false);

  useAsyncEffect(async () => {

    const _isAuthenticated = await auth0.isAuthenticated();

    if (isAuthenticated !== _isAuthenticated) {
      setIsAuthenticated(_isAuthenticated);
    }

    setHasCheckedIsAuthenticated(true);

  }, []);

  return {
    isAuthenticated,
    hasCheckedIsAuthenticated
  };

}

interface UseIsAuthenticated {
  isAuthenticated: boolean,
  hasCheckedIsAuthenticated: boolean
}
