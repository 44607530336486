import axios from 'axios';
import { createClient } from '@aventus/client';
import querystring from 'querystring';
import { interceptResponse } from './intercept-response';
import * as OpusClient from '@aventus/opus-client';

import {
  OrganisationSettings,
  IntelligentQuoteResponse,
  QuoteBundle,
  QuoteProduct,
  PricingPlan,
  Quote,
  Policy,
  PaymentInitialisation,
  Payment,
  FinancePayment,
  StripeCreateIntent,
  SubscriptionInitialisation,
  SubscriptionMTA,
  PricingSet,
  CreditAgreement,
  Risk,
  PaymentMethod,
  DiscountInfo,
  PolicyBundle,
  QuoteEmbargo,
  PolicyActionsWrapper
} from '@aventus/platform';
import * as rax from 'retry-axios';
import { OpusResponse } from '@aventus/opus-client';

export const createOpusClient: OpusClient.CreateOpusClient = function (
  options,
  version,
  auth0,
  externalAuth0Token
) {
  const opus = createClient({
    ...options,
    ...{
      baseURL: `${options.baseURL}/${version}`
    }
  });

  opus.defaults.raxConfig = {
    instance: opus
  };

  rax.attach(opus);

  opus.interceptors.response.use(
    interceptResponse.onFulfilled,
    interceptResponse.onRejected
  );

  let orgSettings: OrganisationSettings;

  // Get an organisation's global configuration settings.
  // These include global definitions for a given org like what
  // timezone they are based in along with what currency.

  const getOrganisationSettings: OpusClient.GetOrganisationSettings =
    async () => {
      if (orgSettings) {
        return orgSettings;
      }

      const response = await opus.get<OpusResponse<OrganisationSettings>>(
        `/config/organisation`,
        { raxConfig: { retry: 3 } }
      );

      orgSettings = response.data.data;
      return response.data.data;
    };

  // Starts a new session for an agent, on behalf of a particular user.
  // This means the agent will then be authorized to perform quote, bind
  // and management actions for the specific user, for the duration
  // of the session.

  const startSession: OpusClient.StartSession = async (
    subjectUserID,
    orgToken
  ) => {
    if (!externalAuth0Token && !auth0 && !orgToken) {
      // Note, we should throw an error here, and kick the user
      // out of the authenticated app.
      console.error('No authentication credentials found');
      return;
    }

    // This Opus endpoint is the only one that requires the direct bearer
    // token that comes from Auth0. This token represents only the agent.
    // When we start a session on behalf of a user, by an agent, we get back
    // a new token that represents the agent acting on behalf of a user.

    // If we've been provided with an externalAuth0Token, then we don't need
    // get the auth0 token from the client as this has already been done for us.

    // We got an org token, assign to externalauth0 token
    if (orgToken) {
      externalAuth0Token = orgToken;
    }

    const agentToken = externalAuth0Token || (await auth0?.getTokenSilently());

    let sessionStartUrl = `${options.baseURL}/session/start`;

    if (subjectUserID) {
      sessionStartUrl += `?subjectUserID=${subjectUserID}`;
    }

    const response = await axios.get<OpusResponse<string>>(sessionStartUrl, {
      headers: { Authorization: `Bearer ${agentToken}` }
    });

    let impersonationToken = response.data.data;

    opus.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${impersonationToken}`;

    return impersonationToken;
  };

  // TODO
  // comment

  const startIntelligentQuoteForNew: OpusClient.StartIntelligentQuoteForNew =
    async (productReferenceID, coverType, quoteID, partnerId) => {
      const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
        `tobes/start?` +
          querystring.stringify({
            productReferenceID,
            coverType,
            quoteID,
            partnerId
          })
      );

      return response.data.data;
    };

  const startIntelligentQuoteForSavedQuote: OpusClient.StartIntelligentQuoteForSavedQuote =
    async quoteID => {
      const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
        `tobes/start/${quoteID}/edit`
      );

      return response.data.data;
    };

  const startAdditionalQuestionsForQuote: OpusClient.StartAdditionalQuestionsForQuote =
    async (quoteId: string, questionSetName: string) => {
      const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
        `tobes/${quoteId}/start?questionsetreferenceid=${questionSetName}`
      );

      let risk = response.data.data.risk;
      let tobesState = response.data.data.tobesState;

      return { risk, tobesState };
    };

  // TODO
  // comment

  const startIntelligentQuoteForRenew: OpusClient.StartIntelligentQuoteForRenew =
    async (quoteID, policyID, partnerId) => {
      const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
        `tobes/startrenewal?` +
          querystring.stringify({
            quoteID,
            policyID,
            partnerId
          })
      );

      return response.data.data;
    };

  // TODO
  // comment

  const startIntelligentQuoteForAdjust: OpusClient.StartIntelligentQuoteForAdjust =
    async (policyID, partnerId) => {
      const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
        `tobes/startmta?` +
          querystring.stringify({
            policyID,
            partnerId,
            questionSetReferenceID: null
          })
      );

      return response.data.data;
    };

  // TODO
  // comment

  const nextIntelligentQuote: OpusClient.NextIntelligentQuote = async (
    risk,
    tobesState
  ) => {
    // ----------------
    // HACKS
    // (https://aventusplatform.atlassian.net/browse/AVT-245)

    if (
      risk.productReferenceID === 'hl_pr_home' &&
      risk.insuredProperty?.addressAsCorrespondence === true
    ) {
      risk.proposer.address = risk.insuredProperty.address;
    }

    if (
      risk.productReferenceID === 'hl_pr_home' &&
      risk.proposer.hasPreviousClaims === false
    ) {
      risk.previousClaims = [];
    }

    // -----------------

    const response = await opus.post<OpusResponse<IntelligentQuoteResponse>>(
      'tobes/next',
      { risk, tobesState }
    );

    return response.data.data;
  };

  // TODO
  // comment

  const nextQuoteIntelligentQuoteForNew: OpusClient.NextQuoteIntelligentQuote =
    async (risk, tobesState, confirm) => {
      const response = await opus.post<OpusResponse<QuoteBundle>>(
        `tobes/nextquote?confirm=${confirm === true ? true : false}`,
        {
          risk,
          tobesState
        }
      );

      return response.data.data;
    };

  // TODO
  // comment

  const nextQuoteIntelligentQuoteForRenew: OpusClient.NextQuoteIntelligentQuote =
    async (risk, tobesState, confirm) => {
      const response = await opus.post<OpusResponse<QuoteBundle>>(
        `tobes/nextquoterenewal?confirm=${confirm === true ? true : false}`,
        { risk, tobesState }
      );

      return response.data.data;
    };

  // TODO
  // comment

  const nextQuoteIntelligentQuoteForAdjust: OpusClient.NextQuoteIntelligentQuote =
    async (risk, tobesState, confirm) => {
      const response = await opus.post<OpusResponse<QuoteBundle>>(
        `tobes/nextquotemta?confirm=${confirm === true ? true : false}`,
        { risk, tobesState }
      );

      return response.data.data;
    };

  const nextQuoteAdditionalQuestion: OpusClient.NextQuoteAdditionalQuestion =
    async (risk, tobesState, confirm) => {
      const response = await opus.post<OpusResponse<IntelligentQuoteResponse>>(
        `/tobes/updatequote?confirm=${confirm === true ? true : false}`,
        { risk, tobesState }
      );
      return response.data.data;
    };

  // TODO
  // comment

  const saveIntelligentQuote: OpusClient.SaveIntelligentquote = async (
    confirmQuote,
    risk,
    tobesState,
    type,
    partnerID,
    partnerReference,
    sourceVriID
  ) => {
    const response = await opus.post<OpusResponse<QuoteBundle>>(`/tobes/save`, {
      confirmQuote,
      risk,
      tobesState,
      type,
      partnerID,
      partnerReference,
      sourceVriID
    });
    return response.data.data;
  };

  // TODO
  // comment

  const getQuoteEmbargoes: OpusClient.GetQuoteEmbargoes = async (
    quoteId: string
  ) => {
    const response = await opus.get<OpusResponse<QuoteEmbargo[]>>(
      `quotes/${quoteId}/embargoes`
    );

    return response.data.data;
  };

  // TODO
  // comment

  const getQuote: OpusClient.GetQuote = async (quoteId: string) => {
    const response = await opus.get<OpusResponse<Quote>>(`quotes/${quoteId}`);

    return response.data.data;
  };

  // TODO
  // comment

  const getQuoteRenewal: OpusClient.GetQuoteRenewal = async (
    renewalQuoteId: string
  ) => {
    const response = await opus.get<OpusResponse<QuoteBundle>>(
      `quotes/renewal/${renewalQuoteId}/bundle`
    );

    return response.data.data;
  };

  // TODO
  // comment

  const getQuotePaymentPlans: OpusClient.GetQuotePaymentPlans = async (
    quoteId: string
  ) => {
    const response = await opus.get<OpusResponse<PricingPlan[]>>(
      `quotes/${quoteId}/paymentplans`
    );

    return response.data.data;
  };

  // TODO
  // comment

  const getQuoteProduct: OpusClient.GetQuoteProduct = async (
    quoteId: string
  ) => {
    const response = await opus.get<OpusResponse<QuoteProduct>>(
      `quotes/${quoteId}/product`
    );

    return response.data.data;
  };

  // TODO
  // comment

  const getPolicy: OpusClient.GetPolicy = async policyId => {
    const response = await opus.get<OpusResponse<Policy>>(
      `policies/${policyId}`
    );
    return response.data.data;
  };

  const getPolicyActions: OpusClient.GetPolicyActions = async policyId => {
    const response = await opus.get<OpusResponse<PolicyActionsWrapper>>(
      `policies/${policyId}/actions`
    );
    return response.data.data;
  };

  const getPolicyPaymentMethod: OpusClient.GetPolicyPaymentMethod =
    async policyId => {
      const response = await opus.get<OpusResponse<PaymentMethod>>(
        `policies/${policyId}/paymentmethod`
      );
      return response.data.data;
    };

  const getPolicyCreditAgreement: OpusClient.GetPolicyCreditAgreement =
    async policyId => {
      const response = await opus.get<OpusResponse<CreditAgreement>>(
        `policies/${policyId}/creditagreement`
      );
      return response.data.data;
    };

  const getPolicyDiscount: OpusClient.GetPolicyDiscount = async policyId => {
    const response = await opus.get<OpusResponse<DiscountInfo>>(
      `policies/${policyId}/discountinfo`
    );
    return response.data.data;
  };

  const getPolicyRisk: OpusClient.GetPolicyRisk = async policyId => {
    const response = await opus.get<OpusResponse<Risk>>(
      `policies/${policyId}/risk`
    );
    return response.data.data;
  };

  const getPolicyBundle: OpusClient.GetPolicyBundle = async policyId => {
    const response = await opus.get<OpusResponse<PolicyBundle>>(
      `policies/${policyId}/bundle`
    );
    return response.data.data;
  };

  // TODO
  // comment

  const getPayment: OpusClient.GetPayment = async paymentId => {
    const response = await opus.get<OpusResponse<Payment>>(
      `payments/${paymentId}`
    );
    return response.data.data;
  };

  // TODO
  // comment

  const getPolicyForPayment: OpusClient.GetPolicyForPayment =
    async paymentId => {
      const response = await opus.get<OpusResponse<Policy>>(
        `payments/${paymentId}/policy`
      );
      return response.data.data;
    };

  // TODO
  // comment

  const createFinanceAgreement: OpusClient.CreateFinanceAgreement = async (
    accountNumber,
    encryptedCreditPlan,
    encryptedDiscount,
    fullName,
    paymentID,
    paymentPlanReferenceID,
    sortCode,
    title
  ) => {
    const response = await opus.post<OpusResponse<FinancePayment>>(
      `payments/finance`,
      {
        accountNumber,
        encryptedCreditPlan,
        encryptedDiscount,
        fullName,
        paymentID,
        paymentPlanReferenceID,
        sortCode,
        title
      }
    );
    return response.data.data;
  };

  /**
   * BNP Finance
   *
   * @returns
   */
  const createBNPFinanceAgreement: OpusClient.CreateBNPFinanceAgreement =
    async (paymentRequest: any) => {
      const response = await opus.post(`payments/bnp/finance`, paymentRequest);
      return response.data.data;
    };

  const createBNPFinanceAgreementNoDeposit: OpusClient.CreateBNPFinanceAgreementNoDeposit =
    async (paymentRequest: any) => {
      const response = await opus.post(`bnp/purchase`, paymentRequest);
      return response.data.data;
    };

  // TODO
  // comment

  const updateFinanceAgreement: OpusClient.UpdateFinanceAgreement =
    async quoteID => {
      const response = await opus.post<OpusResponse<FinancePayment>>(
        `payments/finance/mta`,
        {
          quoteID
        }
      );
      return response.data.data;
    };

  // TODO
  // comment

  const initialisePayment: OpusClient.InitialisePayment = async (
    quoteID,
    encryptedDiscount,
    paymentPlanReferenceID,
    financeRequestData
  ) => {
    const response = await opus.post<OpusResponse<PaymentInitialisation>>(
      `payments/initialise`,
      {
        encryptedDiscount,
        paymentPlanReferenceID,
        quoteID,
        financeRequestData
      }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const initialiseSubscription: OpusClient.InitialiseSubscription = async (
    quoteID,
    encryptedDiscount,
    paymentPlanReferenceID,
    paymentMethodID
  ) => {
    const response = await opus.post<OpusResponse<SubscriptionInitialisation>>(
      `subscriptions/initialise`,
      {
        quoteID,
        encryptedDiscount,
        paymentPlanReferenceID,
        paymentMethodID
      }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const stripeCreateSetupIntent: OpusClient.StripeCreateSetupIntent =
    async () => {
      const response = await opus.get<OpusResponse<StripeCreateIntent>>(
        `stripe/savedcards/setup`
      );
      return response.data.data;
    };

  /**
   * FatZebra Get OAuth Token
   *
   * @param
   * @returns IFatZebraTokenResponse
   */

  const fatZebraGetConfig: OpusClient.FatZebraGetConfig = async (quoteID?: string, policyID?: string) => {
    const response = await opus.get(`fatzebra/config?` + querystring.stringify({
      quoteID,
      policyID
    }));
    return response.data.data;
  };

  /**
   * FatZebra generate payment intent verification token
   *
   * @param props
   * @returns IFatZebraVerificationResponse
   */
  const fatZebraGeneratePaymentIntentVerification: OpusClient.FatZebraGeneratePaymentIntentVerification =
    async props => {
      const response = await opus.post(
        `fatzebra/paymentintent/verification`,
        props
      );
      return response.data.data;
    };

  const fatZebraAnnualPurchase: OpusClient.FatZebraAnnualPurchase =
    async props => {
      const response = await opus.post(`fatzebra/purchase`, props);
      return response.data.data;
    };

  const fatZebraSubscriptionPurchase: OpusClient.FatZebraSubscriptionPurchase =
    async props => {
      const response = await opus.post(`fatzebra/subscription/purchase`, props);
      return response.data.data;
    };

  const fatZebraMidTermAdjustmentRefund: OpusClient.FatZebraMidTermAdjustmentRefund =
    async quoteID => {
      const response = await opus.post(`fatzebra/mtarefund`, {
        quoteID
      });
      return response.data.data;
    };

  const fatZebraSubscriptionMTA: OpusClient.FatZebraSubscriptionMTA =
    async quoteID => {
      const response = await opus.post(`fatzebra/subscription/mta`, {
        quoteID
      });
      return response.data.data;
    };

  // TODO
  // comment

  const adjustRefund: OpusClient.AdjustRefund = async quoteID => {
    const response = await opus.post<OpusResponse<Policy>>(
      `payments/mtarefund`,
      {
        quoteID
      }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const adjustSubscription: OpusClient.AdjustSubscription = async quoteID => {
    const response = await opus.post<OpusResponse<SubscriptionMTA>>(
      `subscriptions/mta`,
      {
        quoteID
      }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const applyVoucherCode: OpusClient.ApplyVoucherCode = async (
    voucherCode,
    quoteID
  ) => {
    const response = await opus.post<OpusResponse<PricingSet>>(
      `payments/voucher`,
      {
        voucherCode,
        quoteID
      }
    );
    return response.data.data;
  };

  const removeVoucherCode: OpusClient.RemoveVoucherCode = async quoteID => {
    const response = await opus.post<OpusResponse<PricingSet>>(
      `payments/${quoteID}/voucher/remove`,
      {
        quoteID
      }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const getVoucherInfo: OpusClient.GetVoucherInfo = async tobesState => {
    const response = await opus.post<OpusResponse<any>>(`tobes/voucher/info`, {
      tobesState
    });
    return response.data.data;
  };

  const removeVoucher: OpusClient.RemoveVoucher = async tobesState => {
    const response = await opus.post<OpusResponse<any>>(
      `tobes/voucher/remove`,
      { tobesState }
    );
    return response.data.data;
  };

  // TODO
  // comment

  const applyVoucher: OpusClient.ApplyVoucher = async (
    risk,
    voucherCode,
    tobesState
  ) => {
    const response = await opus.post<OpusResponse<any>>(`tobes/voucher`, {
      risk,
      voucherCode,
      tobesState
    });
    return response.data.data;
  };

  const getQuoteBundle: OpusClient.GetQuoteBundle = async quoteID => {
    const response = await opus.get<OpusResponse<QuoteBundle>>(
      `quotes/${quoteID}/bundle`
    );

    return response.data.data;
  };

  const editQuote: OpusClient.EditQuote = async renewalQuoteId => {
    const response = await opus.get<OpusResponse<IntelligentQuoteResponse>>(
      `tobes/start/${renewalQuoteId}/editfull`
    );
    return response.data.data;
  };

  return {
    getOrganisationSettings,
    startSession,
    startIntelligentQuoteForNew,
    startIntelligentQuoteForRenew,
    startIntelligentQuoteForAdjust,
    startIntelligentQuoteForSavedQuote,
    nextIntelligentQuote,
    nextQuoteIntelligentQuoteForNew,
    nextQuoteIntelligentQuoteForAdjust,
    nextQuoteIntelligentQuoteForRenew,
    saveIntelligentQuote,
    removeVoucherCode,
    getQuote,
    getQuoteRenewal,
    getQuoteProduct,
    getQuoteEmbargoes,
    getQuotePaymentPlans,
    getPolicy,
    getPolicyActions,
    getPolicyPaymentMethod,
    getPolicyCreditAgreement,
    getPolicyDiscount,
    getPolicyRisk,
    initialisePayment,
    initialiseSubscription,
    getPayment,
    getPolicyForPayment,
    createFinanceAgreement,
    updateFinanceAgreement,
    stripeCreateSetupIntent,

    createBNPFinanceAgreement,
    createBNPFinanceAgreementNoDeposit,

    fatZebraGetConfig,
    fatZebraGeneratePaymentIntentVerification,
    fatZebraAnnualPurchase,
    fatZebraSubscriptionPurchase,
    fatZebraMidTermAdjustmentRefund,
    fatZebraSubscriptionMTA,

    adjustRefund,
    adjustSubscription,
    applyVoucherCode,
    applyVoucher,
    removeVoucher,
    getVoucherInfo,
    startAdditionalQuestionsForQuote,
    nextQuoteAdditionalQuestion,
    getPolicyBundle,
    getQuoteBundle,
    editQuote
  };
};
